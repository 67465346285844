<template>
    <div>
        <div class="page-banner-area" id="page-banner">
            <div class="overlay overlay-sub"></div>
            <div class="container">
                <div class="row justify-content-center" data-aos="zoom-out" data-aos-duration="1500">
                    <div class="col-lg-12 m-auto text-center col-sm-12 col-md-12">
                        <div class="banner-content">
                            <h1 class="text-white">
                                We work closely with valued customers to support cyber capabilities and mission success.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <video loop muted playsinline autobuffer autoplay poster="../images/bg-masthead.jpg" class="video-bkgd">
                <source src="media/nodes.mov" type="video/mp4">
            </video>
        </div>
        <section class="section-padding bg-white">
            <div class="container">
                <div class="row" data-aos="fade-in" data-aos-delay="200" data-aos-duration="1500">
                    <div class="col-lg-12">
                        <h2 class="mb-4">Contact us</h2>
                    </div>
                    <div class="col-lg-8 mb-3">
                        <p>
                            Whether you want to explore working with us, inquire about partnership opportunities, or join the Enlighten team, we look forward to hearing from you.
                        </p>
                    </div>
                    <div class="w-100 d-none d-md-block"></div>
                    <div class="col-lg-4">
                        <h5>General Inquiries</h5>
                        <p class="mb-lg-0">
                            <a href="mailto:info@eitccorp.com" title="info@eitccorp.com">info@eitccorp.com</a>
                        </p>
                    </div>
                    <div class="col-lg-4">
                        <h5>Careers</h5>
                        <p class="mb-lg-0">
                            <a href="mailto:recruiting@eitccorp.com?subject=Careers" title="recruiting@eitccorp.com">recruiting@eitccorp.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="callout bg-gray section-padding">
            <div class="callout-map d-none d-lg-block d-md-block" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-sm-12 col-md-8" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500">
                        <h3 class="mb-2">Our offices</h3>
                        <div class="row">
                            <div class="col-lg-6">
                                <p>
                                    <h5>Columbia, MD <span class="badge badge-pill badge-hq ml-1">HQ</span></h5>
                                    10960 Grantchester Way, Suite 300<br>
                                    Columbia, MD 21044<br>
                                    P. (410) 449-1201 <span class="mx-2">|</span> F. (410) 255-5522
                                </p>
                            </div>
                            <div class="col-lg-6">
                                <p>
                                    <h5>Sykesville, MD</h5>
                                    7564 Main St, Floor 2<br>
                                    Sykesville, MD 21784<br>
                                </p>
                            </div>
                            <div class="col-lg-6">
                                <p>
                                    <h5>Greenville, SC</h5>
                                    552 Memorial Dr Ext (Bellamore)<br>
                                    Greenville, SC 29651<br>
                                </p>
                            </div>
                            <div class="col-lg-6">
                                <p>
                                    <h5>Boise, ID</h5>
                                    1120 S Rackham Way, Suite 300 (Kiln)<br>
                                    Meridian, ID 83642<br>
                                </p>
                            </div>
                            <div class="col-lg-6">
                                <p>
                                    <h5>Augusta, GA</h5>
                                    100 Grace Hopper Ln, Suite 3700 (theClubhou.se)<br>
                                    Augusta, GA 30901<br>
                                </p>
                            </div>
                            <div class="col-lg-6">
                                <p>
                                    <h5>San Antonio, TX</h5>
                                    410127 Morocco St, Suite 219 (Project Cowork)<br>
                                    San Antonio, TX 78216<br>
                                    P. (210) 732-7417
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import analytics from '../analytics.js';

import AOS from 'aos'
import 'aos/dist/aos.css'

const $ = require('jquery');

export default {
    name: 'contact',
    mounted() {
        analytics.sendPageview();
        AOS.init();
    }
}
</script>

<style>

</style>
