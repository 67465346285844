<template>
    <div>
        <div class="banner-area">
            <div class="overlay"></div>
            <div class="full-screen">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-9 col-sm-12 col-md-12 mx-auto text-center">
                            <div class="banner-content content-padding">
                                <h1 class="banner-title" data-aos="zoom-out" data-aos-delay="200" data-aos-duration="1500">
                                    Engineering. Innovation. Mission.
                                </h1>
                            </div>
                            <div class="row text-white" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1500">
                                <div class="col-md-8 mx-auto banner">
                                    <img src="../images/badge-top-work-places.png" alt="Top Work Places 2021-2024" class="mr-4" height="152" width="auto">
                                    <p class="mb-0">
                                        Recognized as Top Workplace for 2021&#8208;2024 by The Baltimore Sun
                                        <a @click="sendClick('/careers')"
                                            title="Join us" class="text-white ml-2">
                                            Join us
                                            <i class="icofont icofont-simple-right ml-1" aria-hidden=”true”></i>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <video loop muted playsinline autobuffer autoplay poster="../images/bg-masthead.jpg" class="video-bkgd">
                <source src="media/nodes.mov" type="video/mp4">
            </video>
        </div>
        <section id="service-head" class="bg-gray">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 m-auto pb-5" data-aos="fade-in"
                        data-aos-delay="200" data-aos-duration="1500">
                        <h2 class="mb-4 text-center">We are Enlighten</h2>
                        <p>
                            <strong>Enlighten, an HII Company</strong>, applies an agile methodology to rapidly 
				support mission-critical capabilities for the DoD, including big data solution 
				development and deployment that prioritizes security and speed. With expertise 
				in software and systems engineering, cloud-based services, cyber capabilities 
				and data science, Enlighten provides continued innovation and proactivity in 
				meeting customers’ greatest challenges.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section id="service">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="service-box" data-aos="fade-up" data-aos-duration="1500">
                            <div class="service-img-icon">
                                <img src="../images/icon/expertise4.png"
                                    alt="Software &amp; Systems Engineering">
                            </div>
                            <div class="service-inner">
                                <h3>Software &amp; Systems Engineering</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="service-box" data-aos="fade-up"
                            data-aos-delay="200" data-aos-duration="1500">
                            <div class="service-img-icon">
                                <img src="../images/icon/expertise1.png"
                                    alt="Cloud-based Services">
                            </div>
                            <div class="service-inner">
                                <h3>Cloud-based Services</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="service-box" data-aos="fade-up"
                            data-aos-delay="400" data-aos-duration="1500">
                            <div class="service-img-icon">
                                <img src="../images/icon/expertise2.png"
                                    alt="Cyber Capabilities">
                            </div>
                            <div class="service-inner">
                                <h3>Cyber Capabilities</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="service-box" data-aos="fade-up"
                            data-aos-delay="600" data-aos-duration="1500">
                            <div class="service-img-icon">
                                <img src="../images/icon/expertise3.png" alt="Data Science">
                            </div>
                            <div class="service-inner">
                                <h3>Data Science</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--  SERVICE AREA END  -->

        <section class="section-padding py-lg-0">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 order-lg-12" data-aos="fade-in"
                        data-aos-delay="600" data-aos-duration="1500">
                        <img src="../images/svg/secure-data-animate.svg"
                            alt="Experts in cybersecurity" class="img-fluid">
                    </div>
                    <div class="col-lg-6 order-lg-1" data-aos="fade-up"
                        data-aos-delay="200" data-aos-duration="1500">
                        <h3 class="mb-4">Experts in cybersecurity</h3>
                        <p>
                            We have worked hard over the years to earn the title “Cybersecurity Experts,” and we stop malicious
                            entities from impacting our customer's networks. We deliver capabilities that support many of our 
                            nation’s leading defense initiatives. Our capabilities include cloud services, cyber solutions, 
                            data science, and software and systems engineering to provide big data management, tools, and security 
                            for our customers.
                        </p>
                        <a @click="sendClick('/about')"
                            title="Learn more about us"
                            class="my-3">
                            Learn more about us
                            <i class="icofont icofont-simple-right ml-1"
                                aria-hidden=”true”></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <!-- EXPLORE JOBS AREA START -->
        <section id="about" class="bg-yellow section-padding">
            <div class="about-bg-img d-none d-lg-block d-md-block"
                data-aos="fade-left" data-aos-delay="600" data-aos-duration="1500">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-sm-12 col-md-8" data-aos="fade-right"
                        data-aos-delay="400" data-aos-duration="1500">
                        <div class="about-content">
                            <h3>Explore job opportunities with Enlighten</h3>
                            <p class="mb-4">
                                <b>Why Enlighten?</b> There are a lot of reasons our employees think Enlighten is a great place to work. At Enlighten, we know it takes more than great pay to continue attracting the best, 
                                most-talented people for the job. That’s why we strive to maintain a collaborative environment 
                                where our employees are respected and recognized for their contributions to our mission. 
                            </p>
                            <ul class="list-inline">
                                <li>
                                    <button type="button"
                                        @click="externalClick(jobsUrl)"
                                        title="Explore job opportunities with Enlighten."
                                        class="btn btn-light">
                                        Explore jobs
                                        <i class="icofont icofont-external-link ml-2"
                                            aria-hidden=”true”></i>
                                    </button>
                                </li>
                                <li>
                                    <a @click="sendClick('/careers')" title="Learn more">
                                        Learn more
                                        <i class="icofont icofont-simple-right ml-1"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import analytics from '../analytics.js';

import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
    name: 'home',
    data() {
        return {
            jobsUrl: 'https://jobs.eitccorp.com/jobs'
        };
    },
    methods: {
        sendClick(hash) {
            analytics.sendPageview(hash);
            this.$router.push(hash);
        },
        externalClick(href) {
            analytics.record(href);
            window.open(href);
        }
    },
    mounted() {
        analytics.sendPageview();
        AOS.init();
    }
}
</script>
